import {
  Autocomplete,
  CircularProgress,
  createTheme,
  debounce,
  Paper,
  TextField,
  ThemeProvider,
} from '@mui/material';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { httpGet } from '../../../utils/httpClient';
import { setSegments } from '../../../utils/store/features/flightSegmentsSlice';
import styles from './FlightSearchAutocomplete.module.scss';

const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: '#00454A',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
});
export const FlightSearchAutocomplete = ({
  label,
  showLabel = true,
  icon,
  name,
  setFieldValue,
  error,
  patch,
}) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [patched, setPatched] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const setInputValue = (value) => {
    setLoading(true);
    const groupBy = function (xs, key) {
      if (!xs || xs.length === 0) return [];
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    httpGet(`/api/airport/autocomplete?search=${value}`).then((response) => {
      response.json().then((result) => {
        if (!result) return;
        const airports = Object.entries(groupBy(result.airports, 'city'))
          .map((group) => group[1])
          .flat();
        setOptions(airports);
        setLoading(false);
      });
    });
  };
  const setValue = (newValue) => {
    setFieldValue(name, newValue);
  };
  useEffect(() => {
    if (!!patch?.value && typeof patch.value === 'string') {
      setPatched(true);
      httpGet(`/api/airport/autocomplete?search=${patch.value}`)
        .then((res) => res.json())
        .then((res) => {
          const airport =
            patch.exact && res.airports.length > 1
              ? res.airports.find(
                  (a) =>
                    !a.Name.includes('All airports') && a.Iata === patch.value
                )
              : res.airports.find(
                  (a) => {
                    // a.Iata === patch.value ||
                    // patch.value
                    //   .replaceAll(' ', '')
                    //   .includes(a.city.replaceAll(' ', ''))
                    const cleanedValue = patch.value.replace(/ /g, '');
                    const cleanedCity = a.city.replace(/ /g, '');
                    return a.Iata === patch.value || cleanedValue.includes(cleanedCity);
                  }
                );
          if (airport) {
            airport.exact = patch.exact;
            setValue(airport);
            setPatched(false);
            dispatch(
              setSegments({
                selectedAirport: airport,
                allAirports: res.airports,
              })
            );
          }
        });
    } else {
      setPatched(true);
    }
  }, [patch.value]);

  return (
    <Autocomplete
      disablePortal={false}
      value={typeof patch.value !== 'string' ? patch.value : null}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      open={open}
      onClose={() => {
        setOpen(false);
        window.parent.postMessage(
          JSON.stringify({
            height: 150,
          }),
          '*'
        );
      }}
      isOptionEqualToValue={(evt) => evt.Name}
      filterOptions={(x) => x}
      onKeyDown={() => {
        setPatched(false);
        setOpen(true);
      }}
      onInputChange={debounce((event, newInputValue) => {
        if (newInputValue !== patch.value?.Name) {
          setInputValue(newInputValue);
          return;
        }
        if (patched === false && newInputValue.length >= 3) {
          setInputValue(newInputValue);
          window.parent.postMessage(
            JSON.stringify({
              height: 400,
            }),
            '*'
          );
        }
      }, 500)}
      PaperComponent={({ children }) => (
        <Paper className={cn(styles.option, 'font-libre')}>{children}</Paper>
      )}
      options={options}
      groupBy={(option) => option.ParentAirport || option.Iata}
      onBlur={() => setOptions([])}
      getOptionLabel={(option) => option.Name || option.name}
      fullWidth
      renderInput={(params) => (
        <ThemeProvider theme={theme}>
          <TextField
            {...params}
            error={error}
            placeholder={label}
            label={showLabel ? label : undefined}
            InputProps={{
              ...params.InputProps,
              startAdornment: <>{icon}</>,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </>
              ),
            }}
          />
        </ThemeProvider>
      )}
    />
  );
};
